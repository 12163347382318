import axios from "axios";

class QueryHelper {
	environment;
	// generics
	base_url;
	base_config;

	constructor({ environment }) {
		this.environment = environment;

		if (this.environment === "production") {
			this.base_url = "https://northstar-recenterhub-core-prod-app.azurewebsites.net/api/app/";
		} else if (this.environment === "staging") {
			this.base_url = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/api/app/";
		} else if (this.environment === "testing") {
			this.base_url = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/api/app/";
		} else {
			this.base_url = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/api/app/";
		}
		this.base_config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
	}

	operators = {
		Equal: 0,
		GreaterThan: 1,
		GreaterThanOrEqual: 2,
		LessThan: 3,
		LessThanOrEqual: 4,
		Between: 5,
		NotEqual: 6,
		NotEmpty: 7,
		Like: 8,
		HasAny: 9,
		Contains: 10,
		StartsWith: 11,
		NotLike: 12,
		ContainsAny: 13,
	};
	pageId = 0;
	pageSize = 50;
	search = [];
	field = "";
	criteria = [];
	orderBy = [];
	fields = [];
	use_q = "/q";

	get payload() {
		return {
			pageId: this.pageId,
			pageSize: this.pageSize,
			criteria: this.criteria,
			orderBy: this.orderBy,
			fields: this.fields,
		};
	}

	setMace() {
		return;
	}

	setNotification() {
		return;
	}

	setMembership() {
		return;
	}

	setMembershipV3() {
		return;
	}

	setMemberPortal() {
		return;
	}

	set payload(payload) {}

	async get(path) {
		const resp = await axios.get(this.base_url + path, this.base_config);
		return resp.data;
	}

	async put(path, payload) {
		const resp = await axios.put(this.base_url + path, payload, this.base_config);
		return resp.data;
	}

	async post(path, payload) {
		const resp = await axios.post(this.base_url + path, payload, this.base_config);
		return resp.data;
	}

	async fetchData(resource) {
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
		return resp.data;
	}

	async fetchDataWithCriteria(resource, criteria) {
		this.criteria = criteria;
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);

		return resp.data;
	}

	async fetchAllData(resource, criteria = []) {
		this.pageSize = 10000;
		let totalPages = 1;
		let results = [];

		if (criteria && criteria.length) {
			this.criteria = criteria;
		}
		let resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
		results = resp.data.results;

		totalPages = resp.data.totalResults / resp.data.pageSize;

		while (this.pageId + 1 < totalPages) {
			this.pageId++;
			resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
			results = results.concat(resp.data.results);
		}

		return results;
	}

	async fetchLookupData(resource) {
		let results = [];

		let resp = await axios.get(`${this.base_url}lookupValues/${resource}`, this.base_config);
		results = resp.data.results;

		return results;
	}

	async fetchResource(resource, id, expanded) {
		let exp = expanded ? "/expanded" : "";
		const resp = await axios.get(this.base_url + resource + "/" + id + exp, this.base_config);
		return resp.data;
	}

	async createResource(resource, payload) {
		const resp = await axios.post(this.base_url + resource, payload, this.base_config);
		return resp.data;
	}

	async updateResource(resource, id, payload) {
		const resp = await axios.put(this.base_url + resource + "/" + id, payload, this.base_config);
		return resp.data;
	}

	async deleteResource(resource, id) {
		const resp = await axios.delete(this.base_url + resource + "/" + id, this.base_config);
		return resp.data;
	}

	async fetchAudienceMembers() {
		const resp = await axios.post(this.MemberDirectory + "AudienceIndex/q", this.payload, this.base_config);
		return resp.data;
	}

	async fetchAudienceTypeAhead(searchString) {
		const resp = await axios.get(this.MemberDirectoryNew + "AudienceIndexes/ta/" + searchString, this.base_config);
		return resp.data;
	}

	async fetchAudienceMember(contact_id) {
		let payload = {
			pageId: 0,
			pageSize: 1,
			criteria: [
				{
					field: "contactId",
					op: 0,
					values: [contact_id],
				},
			],
		};
		const resp = await axios.post(this.MemberDirectory + "AudienceIndex/q", payload, this.base_config);
		return resp.data;
	}

	async fetchAllAudienceMembers() {
		// set limit to max
		this.pageSize = 10000;
		let totalPages = 1;
		let results = [];
		let resp = await axios.post(this.MemberDirectory + "AudienceIndex/q", this.payload, this.base_config);
		results = resp.data.results;

		totalPages = resp.data.totalResults / resp.data.pageSize;

		while (this.pageId + 1 < totalPages) {
			this.pageId++;
			resp = await axios.post(this.MemberDirectory + "AudienceIndex/q", this.payload, this.base_config);
			results = results.concat(resp.data.results);
		}

		return results;
	}

	async sendTestEmail(criteria) {
		const resp = await axios.post(this.CampaignSupport + "Notification/SendPreview", criteria, this.base_config);
		return resp.data;
	}

	async unsubscribe(criteria) {
		const resp = await axios.post(this.Notification + "OptOutRequest/Unsubscribe", criteria, this.base_config);
		return resp.data;
	}

	async csvUpload(audience_id, file) {
		const resp = await axios.post(this.MemberShipV3 + "CustomContacts/uploadCsvFile/" + audience_id, file, this.base_config);
		return resp.data;
	}

	async fetchMemberCount(start_date, end_date) {
		this.criteria = [
			{
				field: "CreatedOn",
				op: 1,
				values: [start_date],
			},
			{
				field: "CreatedOn",
				op: 3,
				values: [end_date],
			},
		];
		this.orderBy = [
			{
				field: "CreatedOn",
				direction: "Desc",
			},
		];
		this.pageSize = 10000;

		const resp = await axios.post(this.MemberDirectory + "MemberCount/q", this.payload, this.base_config);
		return resp.data;
	}

	async fetchMemberRollup(start_date, end_date) {
		this.criteria = [
			{
				field: "CreatedOn",
				op: 1,
				values: [start_date],
			},
			{
				field: "CreatedOn",
				op: 3,
				values: [end_date],
			},
			{
				field: "lastDayOfMonth",
				op: 0,
				values: ["true"],
			},
		];
		this.orderBy = [
			{
				field: "CreatedOn",
				direction: "Desc",
			},
		];
		this.pageSize = 10000;

		const resp = await axios.post(this.MemberDirectory + "MemberCount/q", this.payload, this.base_config);
		return resp.data;
	}

	async fetchListing(listing_id) {
		// eq 'SW18192065'
		if (listing_id && listing_id.length > 4) {
			const resp = await axios.get("https://staging.h.api.crmls.org/RESO/OData/Property?$filter=ListingId eq '" + listing_id + "'&$expand=Media", this.base_config);
			return resp.data;
		}
	}

	async fetchAttendees(class_id = false) {
		if (class_id) {
			this.criteria = [
				{
					field: "trainingClassId",
					op: 0,
					values: [class_id],
				},
			];

			this.orderBy = [
				{
					field: "createdOn",
					direction: 0,
				},
			];
		}

		const resp = await axios.post(this.base_url + "attendee/q", this.payload, this.base_config);
		return resp.data;
	}

	async fetchTopicsByClass(id) {
		return await axios.post(
			`${this.base_url}trainingClass/q?stupid`,
			{
				pageId: 0,
				pageSize: 20,
				criteria: [
					{
						field: "id",
						op: 0,
						values: [id],
					},
				],
			},
			this.base_config
		);
	}

	async getRenderedPdf(campaignId) {
		return await axios.get(`${this.base_url}Campaign/PDFRender/${campaignId}`, this.base_config);
	}
}

export default QueryHelper;
