import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History, LocationState } from "history";
import { reducer as oidcReducer } from "redux-oidc";
import userReducer from "../features/user/userSlice";
import prefsReducer from "../features/prefsFeature/prefsSlice";

const createRootReducer = (history: History<LocationState>) =>
	combineReducers({
		router: connectRouter(history),
		oidc: oidcReducer,
		user: userReducer,
		prefs: prefsReducer,
	});
export default createRootReducer;
