import React, { useEffect, useState } from "react";
import Houses from "../Assets/houses.jpg";
import { memberPortalUrl } from "../adapters";

const AccessDenied = () => {
    const redirectTimer = 10; // After access denied, redirect to member portal in x seconds
    const [redirectTimeout, setRedirectTimeout] = useState(0);

    useEffect(() => {
        setRedirectTimeout(redirectTimer);
    }, []);

    useEffect(() => {
        if (redirectTimeout !== 0) {
            const interval = setInterval(() => {
                // update the state after 1000ms
                setRedirectTimeout((currentCount) => currentCount - 1);
            }, 1000);

            if (redirectTimeout - 1 === 0) {
                redirectToMemberPortal();
            }

            // clean up the interval
            return () => clearInterval(interval);
        }
    }, [redirectTimeout]);

    const redirectToMemberPortal = () => {
        window.location.href = memberPortalUrl;
    }

	return (
		<main className="content bg-white">
			<div className="container-fluid flex d-flex">
				<div className="row flex align-items-center">
					<div className="col-lg-6 d-flex flex h-lg-down full-height bg-pattern bg-fHeight" style={{ backgroundImage: `url(${Houses})` }} />
					<div className="col-lg-4 col-md-5 align-items-center col-sm-6 ml-auto flex d-flex mr-auto full-height pt-40 pb-20">
						<div className="w100 d-block text-center">
							<span className="avatar avatar60 ml-auto mr-auto bg-gradient-primary text-white rounded-circle">
								<i className="icon-Paper-Plane" />
							</span>
							<div className="clearfix pt-3">
								<h5 className="h3 font400">Access Denied</h5>
								<p className="text-muted pt-2">Sorry, you do not have access to this area. You will be redirected to the Member Portal in {redirectTimeout} seconds.</p>
								<div className="clearfix pt-3">
									<button type="button" className="btn btn-primary btn-rounded" onClick={redirectToMemberPortal}>
										<i className="fas fa-sign-in-alt" /> Return Now
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<small className="text-muted">
						<span>
							Photo by{" "}
							<a href="https://unsplash.com/@chuttersnap?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">
								CHUTTERSNAP
							</a>{" "}
							on{" "}
							<a href="https://unsplash.com/s/photos/real-estate?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">
								Unsplash
							</a>
						</span>
					</small>
				</div>
			</div>
		</main>
	);
};

export default AccessDenied;
