import React, { useEffect, useState } from "react";
import Breadcrumbs from "./Components/Widgets/Breadcrumbs";
import Footer from "./Components/Footer";
import LogoBar from "./Components/LogoBar";
import Main from "./Components/Main";
import Header from "./Components/Header";
import { AbilityContext } from "./Auth/can";
import { defineAbility } from "@casl/ability";
import { AppDispatch, RootState, store } from "./app/store";
import { connect } from "react-redux";
import { loadUser } from "redux-oidc";
import userManager from "./Components/Utilities/userManager";
import { AppVersion } from ".";
import ThemeLoader from "./Components/Widgets/ThemeLoader";
import Unauthenticated from "./Components/Unauthenticated";
import { useAppSelector } from "./app/hooks";
import { userProfile } from "./features/user/selectors";
import AccessDenied from "./Components/AccessDenied";

const App = (props: any) => {
	const { oidc_user } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [accessDenied, setAccessDenied] = useState(false);
	const queryParameters = new URLSearchParams(window.location.search);
	let loginAor = queryParameters.get("loginAor");
	const user = useAppSelector(userProfile);

	useEffect(() => {
		if (user && user?.hasCosmosAccess === false) {
			setAccessDenied(true);
		} else {
			setAccessDenied(false);
		}
	}, [user]);

	const caslAbilities = defineAbility((can, cannot) => {
		can("manage", "all"); // able to manage all
		can("read", "all"); // and can read all
	});

	useEffect(() => {
		if (loginAor) {
			loadUser(store, userManager);
			userManager.signinRedirect();
		} else {
			// Give a loading buffer for oidc user state
			setTimeout(() => {
				setIsLoading(false);
			}, 2000);
		}
	}, []);

	return accessDenied ? (
		<AccessDenied />
	) : isLoading ? (
		<ThemeLoader />
	) : oidc_user ? (
		<AbilityContext.Provider value={caslAbilities}>
			<LogoBar version={AppVersion} />
			<main className="content">
				<Header />
				<div className="page-subheader mb-30">
					<Breadcrumbs />
				</div>
				<div id="reactMainContainer" className="page-content d-flex flex">
					<Main />
				</div>
				<Footer />
			</main>
		</AbilityContext.Provider>
	) : (
		<Unauthenticated />
	);
};

function mapStateToProps(state: RootState) {
	return {
		oidc_user: state.oidc.user,
	};
}

function mapDispatchToProps(dispatch: AppDispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
