import React from "react";
import Menu from "./Widgets/Menu";
import { Link } from "react-router-dom";
import { getEnvironmentFromLocation } from "./Utilities/helpers";

const LogoBar = ({ version }: { version: string }) => {
	let environment = getEnvironmentFromLocation();

	const getLogo = () => {
		// let logo = <img alt="Mace" src="/images/CRMLSLogoYellow.png" width="125" />;

		// switch (environment) {
		// 	case "development":
		// 	default:
		// 		logo = <img alt="Mace" src="/images/CRMLSLogoYellow.png" width="125" />;
		// 		break;
		// 	case "testing":
		// 		logo = <img alt="Mace" src="/images/CRMLSLogoGreen.png" width="125" />;
		// 		break;
		// 	case "staging":
		// 		logo = <img alt="Mace" src="/images/CRMLSLogoRed.png" width="125" />;
		// 		break;
		// 	case "production":
		// 		logo = <img alt="Mace" src="/images/CRMLSLogoWhite.png" width="125" />;
		// 		break;
		// }

		// return logo;
		return (
			<div className="d-flex justify-center">
				<img alt="Mace" src="/images/tmp_ns_logo.png" width="200" />
			</div>
		);
	};

	return (
		<aside id="page-aside" className=" page-aside aside-fixed">
			<div className="sidenav darkNav">
				<Link to="/" className="app-logo d-flex flex flex-row align-items-center overflow-hidden justify-content-center">
					<span className="logo-text d-inline-flex">{getLogo()}</span>
				</Link>
				<div className="flex">
					<div id="reactMenu" className="aside-content slim-scroll">
						<Menu />
					</div>
				</div>
				<div className="p-3 pl-25">
					COSMOS Version - <span id="reactVersion">{version}</span>
				</div>
			</div>
		</aside>
	);
};

export default LogoBar;
