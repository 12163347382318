import React from "react";
import Houses from "../Assets/houses.jpg";
import "@sweetalert2/theme-bootstrap-4/bootstrap-4.css";
import userManager from "./Utilities/userManager";

const Unauthenticated = () => {
	const logIn = () => {
		userManager.signinRedirect();
	};

	return (
		<main className="content bg-white">
			<div className="container-fluid flex d-flex">
				<div className="row flex align-items-center">
					<div className="col-lg-6 d-flex flex h-lg-down full-height bg-pattern bg-fHeight" style={{ backgroundImage: `url(${Houses})` }} />
					<div className="col-lg-4 col-md-5 align-items-center col-sm-6 ml-auto flex d-flex mr-auto full-height pt-40 pb-20">
						<div className="w100 d-block text-center">
							<span className="avatar avatar60 ml-auto mr-auto bg-gradient-primary text-white rounded-circle">
								<i className="icon-Paper-Plane" />
							</span>
							<div className="clearfix pt-3">
								<h5 className="h3 font400">Internal Resource</h5>
								<p className="text-muted pt-2">COSMOS is an internal application. If you came across this page in error please notify IT.</p>
								<div className="clearfix pt-3">
									<button type="button" className="btn btn-primary btn-rounded" onClick={logIn}>
										<i className="fas fa-sign-in-alt" /> Log In
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<small className="text-muted">
						<span>
							Photo by{" "}
							<a href="https://unsplash.com/@chuttersnap?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">
								CHUTTERSNAP
							</a>{" "}
							on{" "}
							<a href="https://unsplash.com/s/photos/real-estate?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">
								Unsplash
							</a>
						</span>
					</small>
				</div>
			</div>
		</main>
	);
};

export default Unauthenticated;
