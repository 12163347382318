import React, { useEffect, useState } from "react";
import GenericSyncfusionTable from "../../SubComponents/GenericSyncfusion/GenericSyncfusionTable";
import { SyncfusionOptions } from "../../../Utilities/Configuration";
import dayjs from "dayjs";
import { useAppSelector } from "../../../../app/hooks";
import { userId } from "../../../../features/user/selectors";
import useQuery from "../../../Hooks/Query";
import QueryHelper from "../../../Utilities/QueryHelper";
import Toast from "../../../Widgets/Toast";

export const appCategories = ["AOR", "Broker", "Marketplace", "Member"];
export const getApplicationsResource = (refreshCount?: any): SyncfusionOptions => {
	let options: SyncfusionOptions = {
		resourceType: "operations",
		service: "memberPortal",
		defaultPageSize: 10,
		searchField: "name",
		showDelete: false,
		showClone: false,
		showSaveAndClose: true,
		showSaveAndNew: true,
		resourceName: "Applications",
		title: "Audience Applications",
		singularTitle: "Audience Application",
		routePath: "audience-applications",
		//deleteOnUpdate: ['systemNotificationStatus'],
		defaultSort: {
			columns: [
				{
					field: "createdOn",
					direction: "Descending",
				},
			],
		},
		selectors: [
			{
				id: "applicationCategory",
				label: "Category",
				cssClass: "col-md-2 ml-2",
				service: "mace",
			},
			{
				id: "status",
				label: "Status",
				cssClass: "col-md-2 ml-2",
				service: "mace",
				valueKey: "id",
			},
		],
		columns: [
			{
				id: "iconUrl",
				label: "Icon",
				dataType: "image",
				showInTable: true,
			},
			{
				id: "name",
				label: "Name",
				dataType: "none",
			},
			{
				id: "isDefaultApp",
				label: "Default",
				dataType: "boolean",
				hideAtMedia: "1200",
			},
			{
				id: "status",
				label: "Status",
				dataType: "none",
			},
			{
				id: "shortName",
				label: "Short Name",
				dataType: "none",
			},
			{
				id: "applicationCategory",
				label: "Category",
				dataType: "none",
			},
			{
				id: "applicationCategory",
				label: "",
				dataType: "enumSelect",
				showInTable: false,
				showOnForm: false,
				values: appCategories.map((s) => {
					return {
						value: s,
						label: s,
					};
				}),
			},
			{
				id: "createdOn",
				label: "Created",
				dataType: "date",
				showOnForm: false,
				hideAtMedia: "767",
			},
			{
				id: "modifiedOn",
				label: "Modified",
				dataType: "date",
				showOnForm: false,
				hideAtMedia: "767",
			},
			// these are just for the selectors
			{
				id: "status",
				label: "",
				dataType: "enumSelect",
				showInTable: false,
				showOnForm: false,
				default: "Active",
				values: [
					{
						value: "Active",
						label: "Active",
					},
					{
						value: "Inactive",
						label: "Inactive",
					},
				],
			},
		],
		refreshData: refreshCount,
	};

	return options;
};

function AudienceApplications() {
	const accountId = useAppSelector(userId);
	const [refreshCount, setRefreshCount] = useState<number>(0);
	const [tableOptions, setTableOptions] = useState<SyncfusionOptions>(getApplicationsResource());
	const [helper, setHelper] = useState<QueryHelper | false>(false);
	useQuery(setHelper);

	const toggleDefault = (props: any) => {
		const updatedItem = { ...props };

		updatedItem.isDefaultApp = !props.isDefaultApp;
		updateItem(updatedItem);
	};

	const toggleStatus = (props: any) => {
		const updatedItem = { ...props };

		updatedItem.status = props.status === "Active" ? "Inactive" : "Active";
		updateItem(updatedItem);
	};

	const updateItem = (updatedItem: any) => {
		// Set new properties for default value, modified by and on
		updatedItem.modifiedBy = accountId;
		updatedItem.modifiedOn = dayjs().toISOString();

		// Delete generic syncfusion column specific data
		delete updatedItem.column;
		delete updatedItem.foreignKeyData;
		delete updatedItem.index;

		// Update the application resource with new item - then increment refreshCount to refresh the list view
		if (helper && updatedItem.id) {
			helper.setMemberPortal();
			helper
				.updateResource("Applications", updatedItem.id, updatedItem)
				.then((results) => {
					Toast.fire({
						icon: "success",
						title: "Application updated",
					});
				})
				.then(() => {
					setRefreshCount((prevCount) => prevCount + 1);
				})
				.catch((e) => {
					console.error("Error updating the application", e);
				});
		}
	};

	useEffect(() => {
		tableOptions.actions = [
			{
				label: "Toggle Default",
				iconClass: "fas fa-check text-success",
				callback: toggleDefault,
			},
			{
				label: "Toggle Active",
				iconClass: "fas fa-toggle-on text-success",
				callback: toggleStatus,
			},
		];
		setTableOptions(tableOptions);
	}, [tableOptions]);

	useEffect(() => {
		setTableOptions(getApplicationsResource(refreshCount));
	}, [refreshCount, helper]);

	return (
		<div className="container-fluid">
			<GenericSyncfusionTable options={tableOptions} />
		</div>
	);
}

export default AudienceApplications;
