import React, { useEffect, useState } from "react";
import Routes from "../Utilities/Routes";
import { NavLink, useLocation } from "react-router-dom";

function Menu() {
	let location = useLocation();
	const [activeMenu, setActiveMenu] = useState(location.pathname);

	useEffect(() => {
		setActiveMenu(location.pathname);
	}, [location.pathname]);

	const isActiveSection = (item) => {
		return activeMenu.includes(item.rootpath);
	};

	return (
		<>
			<ul className="metisMenu" id="metisMenu">
				{Routes.filter((item) => !item.hidden).map((item, key) => (
					<li key={key} className={`${isActiveSection(item) ? "active" : ""}`}>
						<i className={item.icon + " nav-thumbnail"} />
						<NavLink className={`${item.paths.length > 0 ? "has-arrow" : ""}`} to={item.rootpath}>
							<span className="nav-text">{item.name}</span>
						</NavLink>
						{item.paths.length > 0 && (
							<>
								<span className="nav-caret" />
								<ul aria-expanded={`${isActiveSection(item) ? "true" : "false"}`} className={`${isActiveSection(item) ? "collapse in" : "collapse"}`}>
									{item.paths.map((path, path_key) => (
										<li key={path_key + path.path} className={`${path.path === activeMenu || (activeMenu.includes(path.path) && path.path !== item.rootpath) ? "active" : ""}`}>
											<i className={path.icon + " nav-thumbnail"} />
											<NavLink to={path.path}>{path.name}</NavLink>
										</li>
									))}
								</ul>
							</>
						)}
					</li>
				))}
			</ul>
		</>
	);
}

export default Menu;
