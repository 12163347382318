import { UserManagerSettings, WebStorageStateStore } from "oidc-client";

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

type environment = "local" | "development" | "testing" | "staging" | "production";

export function getEnvironmentFromLocation(): environment {
	if (window.location.href.includes("localhost") && process.env.NODE_ENV === "development") {
		return "local";
	// } else if (window.location.href.includes("cosmos-lite-dev.crmls.org")) {
	// 	return "development";
	// } else if (window.location.href.includes("cosmos-lite-uat.crmls.org")) {
	// 	return "testing";
	} else if (window.location.href.includes("cosmos-staging.northstarmls.com")) {
		return "staging";
	} else if (window.location.href.includes("cosmos.northstarmls.com")) {
		return "production";
	}

	// fail to dev
	return "development";
}

export function generateUserManagerConfig(): UserManagerSettings {
	let redirect_uri, silent_redirect_uri, post_logout_uri;
	let root: string = "";
	let auth: string = "";
	let clientId = "memberPortalUI";
	let environment = getEnvironmentFromLocation();
	const queryParameters = new URLSearchParams(window.location.search);
	let loginAor = queryParameters.get("loginAor");

	switch (environment) {
		case "local":
			root = "https://localhost:44344";
			auth = "https://signin-dev.northstarmls.com/";
			break;
		// case "development":
		// 	root = "cosmos-lite-dev.crmls.org";
		// 	auth = "https://northstar-recore-idp-dev-appsvc.azurewebsites.net/";
		// 	break;
		// case "testing":
		// 	root = "cosmos-lite-uat.crmls.org";
		// 	auth = "https://northstar-recore-idp-dev-appsvc.azurewebsites.net/";
		// 	break;
		case "staging":
			root = "https://cosmos-staging.northstarmls.com";
			auth = "https://identity-staging.northstarmls.com/";
			break;
		case "production":
			root = "https://cosmos.northstarmls.com";
			auth = "https://signin.northstarmls.com/";
			break;
	}

	redirect_uri = `${root}/callback`;
	silent_redirect_uri = `${root}/silent_renew`;
	post_logout_uri = `${root}/logged-out`;

	return {
		client_id: clientId,
		redirect_uri: redirect_uri,
		response_type: "code",
		response_mode: "query",
		scope: "coredataapi",
		authority: auth,
		silent_redirect_uri: silent_redirect_uri,
		automaticSilentRenew: true,
		filterProtocolClaims: true,
		loadUserInfo: true,
		post_logout_redirect_uri: post_logout_uri,
		monitorSession: false, // this seems to fix logout issue, possibly also related to https://github.com/IdentityModel/oidc-client-js/issues/1319#issuecomment-799944264
		includeIdTokenInSilentRenew: false, // trying this, found at https://github.com/IdentityModel/oidc-client-js/issues/172#issuecomment-593581424
		userStore: new WebStorageStateStore({ store: window.localStorage }), // use local storage - this will allow opening new tabs without being prompted to log in (https://github.com/IdentityModel/oidc-client-js/wiki)
		acr_values: loginAor ? loginAor : "",
	};
}

export function convertUrlToString(url: string) {
	let convertedLabel: any[] = [];
	let convertUrlTab = url.split("-");
	convertUrlTab.forEach((word) => {
		if (word.length > 1) {
			convertedLabel.push(word.charAt(0).toUpperCase() + word.slice(1));
		} else {
			convertedLabel.push(word);
		}
	});
	return convertedLabel.join(" ");
}

export function passwordValidation(value: string) {
	// Password must be at least 8 charcters long and contain at least one uppercase, lowercase, number and special character
	if (value.length >= 8 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)) {
		return true;
	} else {
		return false;
	}
}

export function secondsToReadable(seconds: any) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds - hours * 3600) / 60);
	seconds = seconds - hours * 3600 - minutes * 60;
	if (!!hours) {
		if (!!minutes) {
			return `${hours}h ${minutes}m ${seconds}s`;
		} else {
			return `${hours}h ${seconds}s`;
		}
	}
	if (!!minutes) {
		return `${minutes}m ${seconds}s`;
	}
	return `${seconds} seconds`;
}
