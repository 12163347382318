import { useEffect, useRef } from "react";
import QueryHelper from "../Utilities/QueryHelper";
import { getEnvironmentFromLocation } from "../Utilities/helpers";

function useQuery(callback) {
	let environment = getEnvironmentFromLocation();

	// callback will be a setQueryHelper function
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		if (environment) {
			savedCallback.current(
				new QueryHelper({
					environment: environment,
				})
			);
		}
	}, [environment]);
}

export default useQuery;
