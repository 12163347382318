import React, { useState, useEffect } from "react";
// syncfusion imports
import "@syncfusion/ej2-base/styles/bootstrap.css";
import "@syncfusion/ej2-buttons/styles/bootstrap.css";
import "@syncfusion/ej2-inputs/styles/bootstrap.css";
import "@syncfusion/ej2-react-dropdowns/styles/bootstrap.css";
import useQuery from "../Hooks/Query";
import ThemeLoader from "./ThemeLoader";
import { isArray } from "lodash";

const GenericLookupSelect = ({ id, onChange, onBlur, value, resource_name, service_name, resource_label = "One", cssClass = "form-control", select_field = "name", valueKey = "id" }) => {
	const [items, setItems] = useState([]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [helper, setHelper] = useState(false);
	useQuery(setHelper);

	useEffect(() => {
		if (helper) {
			fetchData();
		}
	}, [helper]);

	const fetchData = () => {
		setIsLoaded(false);

		helper.fetchLookupData(resource_name).then(
			(result) => {
				let results = result;

				setItems(results);
				setIsLoaded(true);
			},
			(error) => {
				setError(error);
				setIsLoaded(true);
			}
		);
	};

	if (error) {
		return <div>Error: {error.message ?? "Could not retrieve results"}</div>;
	} else if (!isLoaded) {
		return <ThemeLoader height={30} width={30} />;
	} else {
		return (
			<select id={id} onChange={onChange} onBlur={onBlur} value={value ?? undefined} className={cssClass}>
				<option value="">Select {resource_label}</option>
				{items.map((i) => {
					return (
						<option key={i.id} value={i[valueKey]}>
							{i[select_field]}
						</option>
					);
				})}
			</select>
		);
	}
};

export default GenericLookupSelect;
