import { Action, AnyAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getEnvironmentFromLocation } from "../Components/Utilities/helpers";

// TODO: Need to set env var or otherwise figure out solid way to determine dev
let base, memberPortal;
let environment = getEnvironmentFromLocation();

// this is assuming staging will be hitting prod endpoints. . .double check this!
switch (environment) {
	case "production":
		base = "https://northstar-recenterhub-core-prod-app.azurewebsites.net/";
		memberPortal = "https://member.northstarmls.com/";
		break;
	case "staging":
		base = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/";
		memberPortal = "https://member-staging.northstarmls.com/";
		break;
	case "local":
	default:
		base = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/";
		memberPortal = "http://localhost:44332/";
		break;
}

export const base_url = base;
export const memberPortalUrl = memberPortal;

export type axiosConfig = {
	token: string;
	endpoint?: string;
};

export type derivedParameters = {
	type: "member" | "office";
	id: string;
};

export type crmlsPayload = {
	pageId: number;
	pageSize: number;
	totalResults: number;
	totalPages: number;
	isSuccessful: boolean;
	results: any[];
	exceptions?: string[];
	message?: string;
};

export type qPayload = {
	pageId: number;
	pageSize: number;
	criteria: qCriteria[];
	orderBy: { field: string; direction: string }[];
};

export type qCriteria = {
	field: string;
	op: string;
	values: any[] | string[];
};

export function defaultCrmlsPayload() {
	return {
		pageId: 0,
		pageSize: 0,
		totalResults: 0,
		totalPages: 0,
		isSuccessful: true,
		results: [],
	};
}

export function get(url: string, id?: string) {
	let req_url = id ? url + id : url;
	return axios.get(req_url);
}

export function post(url: string, requestData: object) {
	return axios.post(url, requestData);
}

export function put(url: string, requestData: object) {
	return axios.put(url, requestData);
}

export function del(url: string, id: string) {
	return axios.delete(url + "/" + id);
}

export interface RejectedAction extends Action {
	error: Error;
}

export function isRejectedAction(action: AnyAction): action is RejectedAction {
	return action.type.endsWith("rejected");
}

export function isPendingAction(action: AnyAction) {
	return action.type.endsWith("pending");
}

export function getMemberByLoginId(id: string, officeId?: string | null) {
	let criteria = {
		pageId: 0,
		pageSize: 1,
		criteria: [
			{
				field: "loginId",
				op: "Equal",
				values: [id],
			},
		],
	};

	return axios.post(base_url + "api/app/AudienceIndex/q", criteria);
}

export function getMemberData() {
	return axios.get(base_url + "api/app/Members/AuthMemberDto");
}
