import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Routes from "../Utilities/Routes";
import "../../CSS/breadcrumbs.css";
import AudienceApplications from "../Main/Operations/AudienceApplications/AudienceApplications";

const Breadcrumbs = () => {
	let location = useLocation();

	const [currentPath, setCurrentPath] = useState(location.pathname);
	const [currentRouteObject, setCurrentRouteObject] = useState({
		path: "/",
		name: "Applications",
		Component: AudienceApplications,
		icon: "fas fa-users",
		showWelcome: false,
		crumbs: [{ name: "Home", path: "/" }],
	});

	useEffect(
		function () {
			setCurrentRouteObject(() => {
				let cp = currentRouteObject;
				Routes.forEach((element) => {
					if (currentPath.includes(element.rootpath)) {
						if (element.paths.length > 0) {
							element.paths.forEach((subpath) => {
								if (currentPath.includes(subpath.path)) {
									cp = subpath;
								}
							});
						} else {
							cp = element;
						}
					}
				});

				return cp;
			});
		},
		[currentPath, currentRouteObject]
	);

	useEffect(
		function () {
			setCurrentPath(location.pathname);
		},
		[location.pathname]
	);

	const getSlug = () => {
		if (currentRouteObject.showWelcome) {
			return (
				<div className="list-body">
					<div className="list-title fs-2x">
						<span className="full-text">
							<strong>
								<h3>COSMOS LITE</h3>
							</strong>
						</span>
					</div>
				</div>
			);
		} else {
			return (
				<div className="list-body">
					<div className="list-title fs-2x">
						<h3>
							<strong>{currentRouteObject.name}</strong>
						</h3>
					</div>
				</div>
			);
		}
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-md-7">
						<div className="list">
							<div className="list-item pl-0">
								<div className="list-thumb ml-0 mr-3 pr-3  b-r text-muted">
									<i className={currentRouteObject.icon} />
								</div>
								{getSlug()}
							</div>
						</div>
					</div>
					<div className="col-md-5 d-flex justify-content-end h-md-down">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb no-padding bg-trans mb-0">
								<li className="breadcrumb-item">
									<NavLink to="/">
										<i className="icon-Home mr-2 fs14" />
									</NavLink>
								</li>
								{currentRouteObject.crumbs.map(({ name, path }, key) =>
									key + 1 === currentRouteObject.crumbs.length ? (
										<li key={key} className="breadcrumb-item active">
											<NavLink key={key} to={path}>
												{name}
											</NavLink>
										</li>
									) : (
										<li key={key} className="breadcrumb-item active">
											<NavLink key={key} to={path}>
												{name}
											</NavLink>
										</li>
									)
								)}
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};

export default Breadcrumbs;
