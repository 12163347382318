import React, { useContext, useEffect, useRef, useState } from "react";
import { OrderByType, selectorQueryDefinition, SyncfusionOptions } from "../../../Utilities/Configuration";
import useQuery from "../../../Hooks/Query";
import QueryHelper from "../../../Utilities/QueryHelper";
import { ColumnChooser, ColumnDirective, ColumnsDirective, GridComponent, Group, Inject, Page, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import { swalWithBootstrapButtons } from "../../../Utilities/SweetAlert";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Link, useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { userId } from "../../../../features/user/selectors";
import { AbilityContext } from "../../../../Auth/can";
import ThemeLoader from "../../../Widgets/ThemeLoader";
import "../../../../CSS/grid.css";
import GenericSyncfusionSelectors from "./GenericSyncfusionSelectors";
import GenericActionsMenu from "./GenericActionsMenu";
import isEqual from "lodash/isEqual";
import { sortBy, isArray } from "lodash";

const initialState = {
	result: [],
	count: 0,
};

const initialQueryData = {
	pageId: 0,
	pageSize: 10,
	criteria: Array(),
	orderBy: Array(),
	fields: Array(),
	refresh: undefined,
};

export const findObjectTitle = (options: SyncfusionOptions) => {
	let title;

	options.columns.forEach((col) => {
		if (col.id === "name" || col.isLabel) {
			title = col.id;
		}
	});

	return title;
};

const GenericSyncfusionTable = ({ options }: { options: SyncfusionOptions }) => {
	const ability = useContext(AbilityContext);
	const accountId = useAppSelector(userId);
	const [activePage, setActivePage] = useState(0);
	const [pageSize, setPageSize] = useState(options.defaultPageSize);
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState<string>();
	const [searchString, setSearchString] = useState("");
	const [selectorQuery, setSelectorQuery] = useState<selectorQueryDefinition[]>([]);
	const [sortParams, setSortParams] = useState<OrderByType[]>([]);
	const [helper, setHelper] = useState<QueryHelper | null>(null);
	const gridControl = useRef<any>("");
	useQuery(setHelper);
	let history = useHistory();
	const [items, setItems] = useState(initialState);
	const [previousQueryData, setPreviousQueryData] = useState<typeof initialQueryData>();
	const [queryData, setQueryData] = useState<typeof initialQueryData>();
	const [refreshData, setRefreshData] = useState(false);

	useEffect(() => {
		if (helper) {
			fetchData();
		}
	}, [helper, activePage, pageSize, searchString, sortParams, options, selectorQuery]);

	useEffect(() => {
		if (options.afterFetchCallback && items) {
			options.afterFetchCallback(items);
		}
	}, [items]);

	useEffect(() => {
		if (!refreshData && isEqual(queryData, previousQueryData)) {
			return;
		}

		if (helper && queryData) {
			const fetchNow = async () => {
				// console.log('ACTUALLY HITTING API', queryData)
				if (isLoaded && gridControl.current) {
					gridControl.current.showSpinner();
				}
				helper
					.fetchData(options.resourceName)
					.then((result) => {
						setIsLoaded(true);
						return result;
					})
					.then((result) => {
						setItems({
							result: result.results,
							count: result.totalResults,
						});
					})
					.then(() => {
						if (isLoaded && gridControl.current) {
							gridControl.current.hideSpinner();
						}
					})
					.catch((error: any) => {
						setError(error.message);
						console.error(`error: ${error}`);
					});
				setPreviousQueryData(queryData);
			};
			fetchNow();

			return function () {
				setRefreshData(false);
			};
		}
	}, [queryData]);

	const fetchData = () => {
		if (helper) {
			helper.criteria = [];
			switch (options.service) {
				case "membershipv3":
					helper.setMembershipV3();
					break;
				case "membership":
					helper.setMembership();
					break;
				case "mace":
					helper.setMace();
					break;
				case "memberPortal":
					helper.setMemberPortal();
					break;
			}

			helper.pageSize = pageSize;
			helper.pageId = activePage;

			if (sortParams.length) {
				helper.orderBy = sortParams;
			} else if (options.defaultSort && options.defaultSort.columns) {
				// convert from syncfusion sort object to q format
				helper.orderBy = options.defaultSort.columns.map((c) => {
					return {
						field: c.field,
						direction: c.direction === "Descending" ? "Desc" : "Asc",
					};
				});
			}

			if (selectorQuery.length) {
				// IF there's a default query, check if there's a selector intentionally set that overrides the
				// default query-- if not, then include the default query alongside the selectors
				if (options.defaultQuery) {
					let setDefault = true;
					options.defaultQuery?.forEach((dq) => {
						let field = dq.field;
						selectorQuery.forEach((sq) => {
							if (sq.field === field) {
								setDefault = false;
							}
						});
					});
					if (setDefault) {
						helper.criteria = options.defaultQuery;
					}
				}

				helper.criteria = [...helper.criteria, ...selectorQuery];
			} else if (options.defaultQuery) {
				helper.criteria = options.defaultQuery;
			}

			helper.criteria = helper.criteria.filter((criteria) => criteria.field !== options.searchField);
			if (searchString.length) {
				helper.criteria.push({
					field: options.searchField,
					op: 10,
					values: [searchString],
				});
			}

			setQueryData({
				pageId: helper.pageId,
				pageSize: helper.pageSize,
				criteria: helper.criteria,
				orderBy: helper.orderBy,
				fields: helper.fields,
				refresh: options.refreshData,
			});
		}
	};

	const handleStateChange = (state: any) => {
		// console.log(state);
		if (state.action) {
			setItems(initialState); // try clearing this here
			if (state.action.requestType === "sorting") {
				// console.log("sorting");
				if ("sorted" in state) {
					let sortOrder: OrderByType[] = [];
					state.sorted.forEach((sort: any) => {
						sortOrder.push({
							field: sort.name,
							direction: sort.direction === "ascending" ? "Asc" : "Desc",
						});
					});
					setActivePage(0);
					setSortParams(sortOrder);
				} else {
					setSortParams([]);
				}
			} else if (state.action.requestType === "searching") {
				// console.log("searching", state.action.searchString);
				setActivePage(0);
				setSearchString(state.action.searchString);
			} else if (state.action.requestType === "paging") {
				// console.log("paging");
				if (state.take !== pageSize) {
					// console.log(state.take)
					setPageSize(state.take);
				}
				if (state.action.currentPage - 1 !== activePage) {
					setActivePage(state.action.currentPage - 1); // we're zero-indexed so subtract
				}
			}
		} else {
			state.skip = 1;
		}
	};

	const pageOptions = {
		pageCount: 4,
		pageSize: pageSize,
		pageSizes: ["5", "10", "20", "50", "100"],
	};

	const deleteObject = (id: string) => {
		swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Delete it!",
				cancelButtonText: "Cancel!",
				reverseButtons: true,
			})
			.then((result) => {
				// console.log(id)
				if (result.isConfirmed && helper) {
					helper.deleteResource(options.resourceName, id).then((result) => {
						options.refreshData = !refreshData;
						fetchData();
						swalWithBootstrapButtons.fire("Deleted!", `${options.singularTitle} deleted.`, "success");
					});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					console.log("cancelled");
				}
			});
	};

	const cloneObject = (object: any) => {
		let newObject = { ...object };
		if (helper) {
			// remove this prop since it comes from syncfusion grid
			delete newObject.column;
			delete newObject.index;
			delete newObject.id;
			delete newObject.createdOn;
			delete newObject.createdBy;
			delete newObject.modifiedOn;
			delete newObject.modifiedBy;

			// remove any model that isn't set
			if (options.deleteOnUpdate?.length) {
				options.deleteOnUpdate.forEach((dOu) => delete newObject[dOu]);
			}

			// get the name of the title object
			let title = findObjectTitle(options);

			if (title) {
				newObject[title] = newObject[title] + " - CLONE";
			}

			newObject.createdBy = accountId;
			newObject.createdOn = dayjs().toISOString();
			newObject.modifiedBy = accountId;
			newObject.modifiedOn = dayjs().toISOString();

			// Not all cloned objects need a draft status - for now, check if this is a campaign, article or training event being cloned, and update the status if so
			// @TODO: This needs to be better thought out - campaigns store 'status' all uppercase 'DRAFT'. Training classes store 'Draft'.
			if (["campaign", "article", "trainingClass"].includes(options.resourceName)) {
				let newStatus = options.resourceName === "campaign" ? "DRAFT" : "Draft";
				newObject.status = newStatus;
			}
			helper
				.createResource(options.resourceName, newObject)
				.then((data) => {
					history.push(`/${options.routePath}/edit/${data.id}`);
				})
				.catch((error: Error) => setError(error.message));
		}
	};

	const nameTemplate = (props: any) => {
		let field = props.column.field; // this will be the actual column
		let tag = props[field] ?? "No Name";
		if (isArray(tag)) {
			tag = tag.join(", ");
		}
		if (options.routePath) {
			let link = `/${options.routePath}/edit/` + props.id;
			return <Link to={link}>{tag}</Link>;
		}

		return tag;
	};

	// this is stupid but syncfusion can't handle more complex templates
	const booleanTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column

		if (value) {
			return (
				<span className={"text-success"}>
					<i className="fas fa-check" />
				</span>
			);
		} else {
			return (
				<span className={"text-danger"}>
					<i className="fas fa-times" />
				</span>
			);
		}
	};

	const imageTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column

		if (value) {
			return <img src={value} alt={props.name ?? ""} className="img-thumbnail" />;
		} else {
			return <i className="fas fa-image" />;
		}
	};

	const selectTemplate = (props: any) => {
		let column = options.columns.find((c) => {
			return c.id === props.column.field && ["staticSelect", "enumSelect", "staticSelectInt"].includes(c.dataType);
		});
		let value = props[props.column.field]; // this will be the actual value of the column
		if (column && column.values) {
			switch (column.dataType) {
				case "enumSelect":
					let enumeration = column.values.find((e: any) => e.value === value);
					if (enumeration) {
						return enumeration.label;
					} else {
						return "No value found for " + value;
					}
				case "staticSelect":
					return value ?? "Undefined";
				case "staticSelectInt":
					return column.values[parseInt(value)];
				default:
					// catchall
					return column.values[value] ?? column.values[parseInt(value)];
			}
		} else {
			return "No value found for " + value;
		}
	};

	const customTemplate = (props: any) => {
		let c = options.columns.find((column) => column.id === props.column.field);
		if (c && c.customComponent) {
			return <c.customComponent object={props} />;
		}
	};

	const actionsTemplate = (props: any) => {
		let itemActions: any[] = [];
		if (options.actions && options.actions.length) {
			itemActions = [
				...options.actions.map((action) => {
					return { ...action, callback: () => action.callback(props) };
				}),
			];
		}

		if (options.showClone === true && ability.can("clone", options.resourceType)) {
			itemActions.push({
				label: "Clone Item",
				iconClass: "far fa-copy text-info",
				callback: () => cloneObject(props),
			});
		}

		if (options.showDelete === true && ability.can("delete", options.resourceType)) {
			itemActions.push({
				label: "Delete Item",
				iconClass: "far fa-trash-alt text-danger",
				callback: () => deleteObject(props.id),
			});
		}

		return <GenericActionsMenu actions={itemActions} />;
	};

	const dateTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		if (value) {
			return dayjs(value).format("MMM D, YYYY hh:mm A");
		}
	};

	const subResourceTemplate = (props: any) => {
		let column = options.columns.find((c) => c.id === props.column.field);

		// this template is used if we need to drill down into a subresource using dot notation
		// so we'll split the column name on the dots
		let columnParts = props.column.field.split(".");

		// TODO: hardcode this to nesting only one level deep for now
		let value = null;

		if (props.hasOwnProperty(columnParts[0]) && props[columnParts[0]]) {
			value = props[columnParts[0]][columnParts[1]]; // this will be the actual value of the column
		}

		if (value) {
			if (column && column.isLabel) {
				let link = `/${options.routePath}/edit/` + props.id;
				return <Link to={link}>{value}</Link>;
			}
			return value;
		} else {
			return "";
		}
	};

	const buildFilters = () => {
		if (!options.selectors || options.selectors.length === 0) {
			return <></>;
		}

		return <GenericSyncfusionSelectors options={options} setSelectorQuery={setSelectorQuery} showClearBtn={true} />;
	};

	// Table header toolbar actions
	let toolbarActions = [];
	if (options.searchField) {
		toolbarActions.push("Search");
	}
	if (options.showRefresh !== false) {
		toolbarActions.push({
			text: "Refresh",
			tooltipText: "Refresh List",
			prefixIcon: "e-refresh",
			id: "RefreshView",
		});
	}
	toolbarActions.push("ColumnChooser");

	const handleToolbarClick = (args: any) => {
		if (args.item.id === "RefreshView") {
			setRefreshData(true);
			fetchData();
		}
	};

	const getCustomToolbarAction = (tool: any, key: number) => {
		return (
			<li key={key} className="nav-item">
				{tool}
			</li>
		);
	};

	let tableColumns = options.columns.filter((col) => col.showInTable !== false);
	tableColumns = sortBy(tableColumns, "tableOrder");

	if (error?.length) {
		return <div className="alert alert-danger">{error}</div>;
	} else if (isLoaded) {
		return (
			<>
				<div className="row">
					<div className="portlet-box portlet-fullHeight border0 shadow-sm mb-30">
						<div className="portlet-header flex-row flex d-flex align-items-center b-b">
							<div className="flex d-flex flex-column">
								<h3 dangerouslySetInnerHTML={{ __html: options.title }}></h3>
							</div>
							<div className="portlet-tools">
								<ul className="nav">
									{ability.can("manage", options.resourceType) && options.showNew !== false && options.routePath && (
										<li className="nav-item">
											<Link to={`/${options.routePath}/new`} className="btn btn-sm btn-icon btn-success btn-square">
												<i className="fa fa-plus" /> New
											</Link>
										</li>
									)}
									{/* Additional toolbar options */}
									{options.customToolbar && options.customToolbar.length && options.customToolbar.map((tool, key) => getCustomToolbarAction(tool, key))}
								</ul>
							</div>
						</div>
						<div className="portlet-body no-padding">
							{buildFilters()}
							<div className={"table-responsive col-lg-12 b-r pt-20 pb-20 no-border-lg-down"}>
								<GridComponent
									className="table-striped"
									dataSource={items}
									allowPaging={true}
									allowSorting={true}
									pageSettings={pageOptions}
									showColumnChooser={true}
									enablePersistence={false}
									toolbar={toolbarActions}
									toolbarClick={handleToolbarClick}
									dataStateChange={handleStateChange}
									ref={gridControl}
									resizeSettings={{ mode: "Auto" }}
									dataBound={() => gridControl.current.autoFitColumns([])}
								>
									<ColumnsDirective>
										{tableColumns.map((c) => {
											let columnProperties: { [key: string]: any } = {
												key: c.id,
												field: c.id,
												width: c.width ?? undefined,
												headerText: c.label,
												clipMode: "EllipsisWithTooltip",
												hideAtMedia: c.hideAtMedia ? `(min-width: ${c.hideAtMedia}px)` : undefined,
												textAlign: c.textAlign ?? undefined,
												allowSorting: c.allowSorting ?? undefined,
											};

											switch (c.dataType) {
												case "staticSelect":
												case "staticSelectInt":
												case "enumSelect":
													//columnProperties.headerText = '';
													columnProperties.template = selectTemplate;
													break;
												case "image":
													columnProperties.template = imageTemplate;
													columnProperties.textAlign = "center";
													break;
												case "subResource":
													columnProperties.template = subResourceTemplate;
													break;
												case "date":
													columnProperties.template = dateTemplate;
													break;
												case "currency":
													// add any specific updates for currency formatting, etc
													break;
												case "boolean":
													columnProperties.template = booleanTemplate;
													break;
												case "custom":
													columnProperties.template = customTemplate;
													break;
												default:
													columnProperties.template = nameTemplate;
													columnProperties.maxWidth = 290;
													break;
											}

											return (
												<ColumnDirective
													key={columnProperties.key}
													field={columnProperties.field}
													template={columnProperties.template}
													headerText={columnProperties.headerText}
													clipMode={columnProperties.clipMode}
													hideAtMedia={columnProperties.hideAtMedia}
													textAlign={columnProperties.textAlign}
													type={columnProperties.type ?? undefined}
													format={columnProperties.format ?? undefined}
													width={columnProperties.width ?? undefined}
													maxWidth={columnProperties.maxWidth ?? undefined}
													allowSorting={columnProperties.allowSorting ?? true}
												/>
											);
										})}
										{ability.can("manage", options.resourceType) && (options.showDelete || options.showClone || options.showEdit || (options.actions && options.actions.length)) && (
											<ColumnDirective field={"actions"} headerText="Actions" template={actionsTemplate} textAlign={"Right"} allowSorting={false} />
										)}
									</ColumnsDirective>
									<Inject services={[Page, Sort, Group, Search, Toolbar, Resize, ColumnChooser]} />
								</GridComponent>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <ThemeLoader />;
	}
};

export default GenericSyncfusionTable;
