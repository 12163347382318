/*
 * NOTE: use fontawesome 5 free icons or icomoon icons but use full icon class, with fas etc.
 * FA 5: https://fontawesome.com/icons?d=gallery&q=buildin&m=free
 * IcoMoon: https://iconsmind.com/view_icons/
 */

const Routes = [

	{
		name: "Applications",
		icon: "fas fa-users",
		abbrev: "AA",
		rootpath: "/audience-applications",
		showWelcome: false,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Applications", path: "/audience-applications" },
		],
	},
	{
		name: "Audiences",
		icon: "fas fa-users",
		abbrev: "AA",
		rootpath: "/audiences",
		showWelcome: false,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Audiences", path: "/audiences" },
		],
	},
	{
		name: "Custom Canvases",
		icon: "fas fa-table",
		abbrev: "CC",
		rootpath: "/custom-canvases",
		showWelcome: false,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Custom Canvases", path: "/custom-canvases" },
		],
	},
	{
		name: "EULA Manager",
		icon: "fas fa-file-signature",
		abbrev: "EM",
		rootpath: "/eula",
		showWelcome: false,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "EULA Manager", path: "/eula" },
		],
	},
	{
		name: "Media Manager",
		icon: "fas fa-photo-video",
		abbrev: "MM",
		rootpath: "/media",
		showWelcome: false,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Media Manager", path: "/media" },
		],
	},
	{
		name: "System Messages",
		icon: "fas fa-bell",
		abbrev: "SM",
		rootpath: "/system-alerts",
		showWelcome: false,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "System Messages", path: "/system-alerts" },
		],
		// if we want submenu for this main nav item - remove paths and crumbs from above and use this structure
		// paths: [
		// 	{
		// 		path: "/operations/system-alerts",
		// 		name: "System Messages",
		// 		icon: "fas fa-bell",
		// 		showWelcome: false,
		// 		crumbs: [
		// 			{ name: "Home", path: "/" },
		// 			{ name: "Operations", path: "/operations" },
		// 		],
		// 	},
		// ],
	},
	{
		name: "Templates",
		icon: "fas fa-file",
		abbrev: "TT",
		rootpath: "/templates",
		showWelcome: false,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Templates", path: "/templates" },
		],
	},
	{
		name: "Logged Out",
		icon: "fas fa-cog",
		abbrev: "LO",
		rootpath: "/logged-out",
		showWelcome: false,
		hidden: true,
		paths: [],
		crumbs: [
			{ name: "Home", path: "/" },
		],
	},
];

export default Routes;
