import React, { useState, useEffect } from "react";
// syncfusion imports
import "@syncfusion/ej2-base/styles/bootstrap.css";
import "@syncfusion/ej2-buttons/styles/bootstrap.css";
import "@syncfusion/ej2-inputs/styles/bootstrap.css";
import "@syncfusion/ej2-react-dropdowns/styles/bootstrap.css";
import useQuery from "../Hooks/Query";
import ThemeLoader from "./ThemeLoader";
import { isArray } from "lodash";

const GenericSelect = ({ id, onChange, onBlur, value, resource_name, service_name, resource_label = "One", cssClass = "form-control", select_field = "name", valueKey = "id" }) => {
	const [items, setItems] = useState([]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [helper, setHelper] = useState(false);
	useQuery(setHelper);

	const userFilters = [
		{ service: "mace", department: ["marketing", "training"] },
		{ service: "training", department: "training" },
	];

	useEffect(() => {
		if (helper) {
			fetchData();
		}
	}, [helper]);

	const fetchData = () => {
		setIsLoaded(false);
		switch (service_name) {
			case "membershipv3":
				helper.setMembershipV3();
				break;
			case "membership":
				helper.setMembership();
				break;
			case "mace":
				helper.setMace();
				break;
			case "memberPortal":
				helper.setMemberPortal();
				break;
		}

		helper.criteria = [];
		helper.orderBy = [
			{
				field: select_field,
				direction: 0,
			},
		];

		helper.fetchAllData(resource_name).then(
			(result) => {
				let results = result;

				// Filter user dropdown to relevant department users
				if (resource_name === "user" && userFilters.find((filter) => filter.service === service_name)) {
					let filteredUsers;
					const departmentFilter = userFilters.find((filter) => filter.service === service_name);

					if (departmentFilter && isArray(departmentFilter.department)) {
						filteredUsers = result.filter((r) => departmentFilter.department.includes(r.department.toLowerCase()));
					} else if (departmentFilter) {
						filteredUsers = results.filter((r) => r.department.toLowerCase() === departmentFilter.department);
					}

					if (filteredUsers.length) {
						results = filteredUsers;
					}
					results = results.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
					results.map((result) => {
						return (result["displayName"] = result.displayName ?? result.firstName + " " + result.lastName);
					});
				}

				setItems(results);
				setIsLoaded(true);
			},
			(error) => {
				setError(error);
				setIsLoaded(true);
			}
		);
	};

	if (error) {
		return <div>Error: {error.message ?? "Could not retrieve results"}</div>;
	} else if (!isLoaded) {
		return <ThemeLoader height={30} width={30} />;
	} else {
		return (
			<select id={id} onChange={onChange} onBlur={onBlur} value={value ?? undefined} className={cssClass}>
				<option value="">Select {resource_label}</option>
				{items.map((i) => {
					return (
						<option key={i.id} value={i[valueKey]}>
							{i[select_field]}
						</option>
					);
				})}
			</select>
		);
	}
};

export default GenericSelect;
