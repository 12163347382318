import React from "react";
import { useAppSelector } from "../../app/hooks";
import { userProfile } from "../../features/user/selectors";
import userManager from "../Utilities/userManager";

function User() {
	const user = useAppSelector(userProfile);

	const logOut = () => {
		setTimeout(async () => {
			const userIdToken = (await userManager.getUser())?.id_token;
			userManager.signoutRedirect({ id_token_hint: userIdToken });
		}, 2000);
	};

	return (
		<>
			<a className="nav-link" href="#" id="dropdown-user" role="button" data-toggle="dropdown">
				<span className="user-states states-online">
					{/* <img src={user?.member?.image} width="35" alt="" className="img-fluid rounded-circle" /> */}
					<img className="img-fluid rounded-circle" src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user.memberFirstName + " " + user.memberLastName}`} alt="" />
				</span>
				<span className="ml-2 h-lg-down dropdown-toggle">Hi, {user?.memberFirstName ?? user?.userid}</span>
			</a>
			<div className="dropdown-menu dropdown-menu-right">
				<div className="text-center p-3 pt-0 b-b mb-5">
					<span className="mb-0 d-block font300 text-title fs-1x">
						Hi!{" "}
						<span className="font700">
							{user?.memberFirstName ?? user?.userid} {user?.memberLastName}
						</span>
					</span>
				</div>
				{/*<a className="dropdown-item" href="#"><i className="icon-User"></i>My Profile</a>*/}
				<div className="dropdown-item" role="button" onClick={logOut}>
					<i className="icon-Power"></i> logout
				</div>
			</div>
		</>
	);
}

export default User;
