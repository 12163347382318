import React from "react";
import User from "./Widgets/User";
import { Link } from "react-router-dom";

const Header = () => {
	return (
		<header className="navbar page-header whiteHeader navbar-expand-lg">
			<ul className="nav flex-row mr-auto">
				<li className="nav-item">
					<a href="#" className="nav-link sidenav-btn h-lg-down">
						<span className="navbar-toggler-icon" />
					</a>
					<a className="nav-link sidenav-btn h-lg-up" href="#page-aside" data-toggle="dropdown" data-target="#page-aside">
						<span className="navbar-toggler-icon" />
					</a>
				</li>
			</ul>
			<ul className="nav flex-row order-lg-2 ml-auto nav-icons">
				<li className="nav-item dropdown user-dropdown align-items-center">
					<User />
				</li>
				<li className="h-lg-up nav-item">
					<a href="#" className=" nav-link collapsed" data-toggle="collapse" data-target="#navbarToggler" aria-expanded="false">
						<i className="icon-Magnifi-Glass2" />
					</a>
				</li>
			</ul>
		</header>
	);
};

export default Header;
